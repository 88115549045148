import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Dashboard.css';

function Dashboard() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(new Date());

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/dashboard/users', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUsers(response.data);
    } catch (err) {
      setError(err.message || 'An error occurred while fetching users');
    }
  };

  const handleEdit = (user) => {
    setEditingUser(user);
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`/api/dashboard/users/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        fetchUsers();
      } catch (err) {
        setError(err.message || 'An error occurred while deleting the user');
      }
    }
  };

  const handleSave = async (updatedUser) => {
    try {
      await axios.put(`/api/dashboard/users/${updatedUser._id}`, updatedUser, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setEditingUser(null);
      fetchUsers();
    } catch (err) {
      setError(err.message || 'An error occurred while updating the user');
    }
  };

  const handleAddUser = async (newUser) => {
    try {
      await axios.post('/api/dashboard/users', newUser, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setIsAddingUser(false);
      fetchUsers();
    } catch (err) {
      setError(err.message || 'An error occurred while adding the user');
    }
  };

  const handleSendMessage = async (user) => {
    try {
      await axios.post('/api/dashboard/send-message', user, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      alert('Mensaje enviado con éxito');
    } catch (err) {
      setError(err.message || 'An error occurred while sending the message');
    }
  };

  const handleScheduleMessage = async (user) => {
    const message = prompt("Ingrese el mensaje a enviar:");
    if (message) {
      try {
        const response = await axios.post('/api/dashboard/schedule-message', {
          userId: user._id,
          scheduledDate: scheduledDate.toISOString(),
          message
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Respuesta del servidor:', response.data);
        alert('Mensaje programado con éxito');
        fetchUsers();
      } catch (err) {
        console.error('Error completo:', err);
        setError(err.response?.data?.message || err.message || 'An error occurred while scheduling the message');
      }
    }
  };

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <button onClick={() => setIsAddingUser(true)} className="add-user-button">Add New User</button>
      <h2>Users</h2>
      <table className="users-table">
        <thead>
          <tr>
            <th>Phone Number</th>
            <th>Name</th>
            <th>Subscribed</th>
            <th>Preferencia</th>
            <th>Scheduled Message</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id || user.phoneNumber}>
              <td>{user.phoneNumber}</td>
              <td>{user.customContent?.name || 'N/A'}</td>
              <td>{user.isSubscribed ? 'Yes' : 'No'}</td>
              <td>{user.customContent?.preferencia || 'N/A'}</td>
              <td>{user.scheduledMessage ? new Date(user.scheduledMessage.date).toLocaleString() : 'N/A'}</td>
              <td>
                <button className="edit-button" onClick={() => handleEdit(user)}>Edit</button>
                <button className="delete-button" onClick={() => handleDelete(user._id)}>Delete</button>
                <button className="send-message-button" onClick={() => handleSendMessage(user)}>Enviar mensaje</button>
                <DatePicker
                  selected={scheduledDate}
                  onChange={(date) => setScheduledDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Hora"
                  dateFormat="yyyy-MM-dd HH:mm"
                />
                <button className="schedule-message-button" onClick={() => handleScheduleMessage(user)}>Programar mensaje</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingUser && (
        <EditModal user={editingUser} onSave={handleSave} onClose={() => setEditingUser(null)} />
      )}
      {isAddingUser && (
        <AddUserModal onSave={handleAddUser} onClose={() => setIsAddingUser(false)} />
      )}
    </div>
  );
}

function EditModal({ user, onSave, onClose }) {
  const [editedUser, setEditedUser] = useState({
    ...user,
    customContent: {
      ...user.customContent,
      name: user.customContent?.name || '',
      preferencia: user.customContent?.preferencia || ''
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' || name === 'preferencia') {
      setEditedUser({
        ...editedUser,
        customContent: {
          ...editedUser.customContent,
          [name]: value
        }
      });
    } else {
      setEditedUser({
        ...editedUser,
        [name]: name === 'isSubscribed' ? value === 'true' : value
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedUser);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Edit User</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Phone Number:</label>
            <input 
              type="text" 
              name="phoneNumber" 
              value={editedUser.phoneNumber} 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label>Name:</label>
            <input 
              type="text" 
              name="name" 
              value={editedUser.customContent.name} 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label>Subscribed:</label>
            <select 
              name="isSubscribed" 
              value={editedUser.isSubscribed.toString()} 
              onChange={handleChange}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div>
            <label>Preferencia:</label>
            <textarea 
              name="preferencia" 
              value={editedUser.customContent.preferencia} 
              onChange={handleChange} 
              rows="4"
            />
          </div>
          <button type="submit">Save</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
}

function AddUserModal({ onSave, onClose }) {
  const [newUser, setNewUser] = useState({
    phoneNumber: '',
    isSubscribed: false,
    customContent: {
      name: '',
      preferencia: ''
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' || name === 'preferencia') {
      setNewUser({
        ...newUser,
        customContent: {
          ...newUser.customContent,
          [name]: value
        }
      });
    } else {
      setNewUser({
        ...newUser,
        [name]: name === 'isSubscribed' ? value === 'true' : value
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(newUser);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add New User</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Phone Number:</label>
            <input 
              type="text" 
              name="phoneNumber" 
              value={newUser.phoneNumber} 
              onChange={handleChange} 
              required
            />
          </div>
          <div>
            <label>Name:</label>
            <input 
              type="text" 
              name="name" 
              value={newUser.customContent.name} 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label>Subscribed:</label>
            <select 
              name="isSubscribed" 
              value={newUser.isSubscribed.toString()} 
              onChange={handleChange}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div>
            <label>Preferencia:</label>
            <textarea 
              name="preferencia" 
              value={newUser.customContent.preferencia} 
              onChange={handleChange} 
              rows="4"
            />
          </div>
          <button type="submit">Save</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
}

export default Dashboard;
